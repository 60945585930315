<template>
  <div class="list-info" id="watermark">
    <top-bar :title="'社区党建'" :left="true"></top-bar>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <div class="search">
      <input v-model="searchValue" type="text" placeholder="请输入名字/证件号/手机号搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>

    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{item.communityStr}}/<span style="color:#387FF5">{{item.postName}}</span>
              </div>
              <div class="title-right">
                <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/phone.png" alt="" class="tel"/></a>
              </div>
            </div>
            <div class="content">
              <img :src="manHeadImg" class="content-img">
              <div class="content-text">
                <div class="top-text">{{item.userName}}/{{item.partyType==2?'预备党员':'正式党员'}}</div>
                <div class="bottom-text">{{item.mobile}}</div>
                <div class="bottom-text">{{item.idNumber}}</div>
                <div class="right-text" style="right:0">{{item.joinDate}}入党</div>
              </div>
              <van-icon name="arrow" color="#666" class="bottom-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script >
import topBar from '@/components/topBar/topBar'
import {util} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {mapMutations, mapState} from 'vuex'
export default {
  name: "branch-info",
  computed:{
    ...mapState(['partyId','branchId']),
    manHeadImg () {
      return getImageStream('files/wx/images/content/headImgMan.png')
    },
    womanHeadImg () {
      return getImageStream('files/wx/images/content/headImgWoman.png')
    }
  },
  components :{
    topBar
  },
  data() {
    return {
      searchValue: "",
      loading: false,
      pullLoading: false,
      finished: false,
      limit: 10,
      page: 0,
      dataList: []
    };
  },
  methods: {
    ...mapMutations(['setParty']),
    getInfo (id) {
      this.$store.commit('setParty', id)
      this.$router.push('/party-info')
    },
    getDataList () {
      console.log(this.partyId)
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/party/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          appOrgId: this.$orgId,
          orgId: this.$orgId,
          branchId:this.branchId,
          branchName:""
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
      // 数据全部加载完成
    },
    onPullDownRefresh(e) {
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch(val) {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    goAdd(){
      this.$router.push('/party-info-add')
    },
  },
  created () {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
  }
}
</script>
<style scoped>
.search {
  padding-bottom: 20px
}
.top-text {
  font-size: 26px !important;
}
.bottom-text {
  margin-top: 10px !important;
  font-size: 24px !important;
}
.list-info .cont .list-item:nth-child(2) {
  margin-top: 20px;
}
</style>

